.openingText{
    text-align: center;
    font-size: 1.25rem;
    margin-top: 44px;
}
.warningText{
    text-align: center;
    font-size: 1.25rem;
    color: red;
    margin-top: 44px;
}
.flexContainer{
    display: flex;
    min-height: 92vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media(max-width: 1024px){
    .openingText{
        font-size: 1rem;
    }
    .warningText{
        font-size: 1rem;
    }
}