.center{
    display: flex;
    min-height: 92vh;
    justify-content: center;
    align-items: center;
}
.card{
    border: 1px solid black;
    padding: 20px 0;
    margin-top: 44px;
    text-align: center;
    font-size: 1.25rem;
}
.card > *{
    margin: 20px 0;
}
.rankings{
    font-size: 1.25rem;
    text-align: center;
    margin-top: 44px;
}