.entryBox {
  font-family: Arial, Helvetica, sans-serif;
  display: block;
  margin: 44px auto 0 auto;
  padding: 10px;
  resize: none;
  width: 100%;
  height: 400px;
  line-height: 30px;
  font-size: 1.25rem;
}
@media (max-width: 1024px) {
  .entryBox {
    height: 250px;
  }
}
