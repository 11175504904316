.flexSection{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 92vh;
    flex-direction: column;
    padding: 44px 0;
}
.infoText{
    text-align: center;
    height: 4rem;
    font-size: 1.25rem;
}
.card{
    border: 1px solid black;
    padding-bottom: 25px;
    text-align: center;
}
.card > *{
    margin: 20px 0;
}
.btn{
    padding: 10px;
    width: 140px;
    text-align: center;
    border-radius: 10px;
    margin: 0 auto;
    cursor: pointer;
}
.blue{
    background-color:  #0099ff;
    color: white;
}
.blue:hover{
    background-color: #008ae6;
}
.green{
    background-color: #04AA6B;
    color: white;
}
.green.initialBtn{
    width: 100px;
    margin-top: 1.5rem;
}
.green:hover{
    background-color: #059862;
}
.options{
    font-size: 1.25rem;
}
.question{
    font-size: 3rem;
    height: 5.5rem;
}
.backBtn{
    margin: 44px auto 0 auto;
    padding: 15px 0;
    text-align: center;
    background-color: #04AA6B;
    cursor: pointer;
    color: white;
    border-radius: 10px;
    font-weight: bold;
    width: 100px;
}
.initialScreen{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    z-index: -1;
    justify-content: center;
    align-items: center;
}
.initialText{
    text-align: center;
    font-size: 1.25rem;
    padding: 20px 0 0 0;
}
.enteredTitle{
    font-size: 2rem;
    font-weight: bold;
    text-transform: capitalize;
    text-align: center;
}
.enteredSub{
    font-size: 1.25rem;
    text-align: center;
}