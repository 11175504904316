*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
}
.container{
  margin: 0 auto;
  padding: 0 16px;
  width: 100%;
  max-width: 1140px;
}
a, a:link, a:visited, a:hover, a:active{
  color: inherit;
  text-decoration: none;
}
button, input, select, textarea {
  font-family: inherit;
  font-size: 100%;
}

